import React, { useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInputModal from '../Company/OtpInputModal';
import ProjectsService from '../../Services/ProjectsService';
import './AllTabs.css';
import { ToastContainer, toast ,Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserService from '../../Services/UserService';
const ChangePhoneNumber = () => {
  
  const [countryCode, setCountryCode] = useState('');
  const [isUpdateButtonActive, setIsUpdateButtonActive] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [OtpModalOpen, setOtpModalOpen] = useState(false);
  const [OtpDataFromModal,setOtpDataFromModal] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleOtp = (OtpDataToProject) => {
    setOtpDataFromModal(OtpDataToProject);
  }
 
  console.log("phoneNumber ---"+phoneNumber)
    let userDetailsObject = {
        mobile: ""
      };
      userDetailsObject.mobile="+".concat(phoneNumber);
   
     var otpInputModelOpenendForAction = '';



      async function verifyOtp(OtpDataFromModal){
  
        if(OtpDataFromModal.length!==0){
          console.log(OtpDataFromModal)
          OtpDataFromModal  = Number(OtpDataFromModal.join(''));
          console.log("otp:"+OtpDataFromModal)
          console.log("userDetailsObject"+userDetailsObject.mobile);
          await UserService.activateMobileNumber(OtpDataFromModal).then(async(response) => {
            await UserService.refereshUser();
                setIsUpdateButtonActive(false); 
                setOtpModalOpen(false);
                toast.success(response.data.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 8000, //6 seconds
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  transition: Slide
                });
                setModalShow(true);
                setTimeout(() => { 
                  window.location.reload();
              }, 4000);
            }).catch(error =>{
                console.log(error);
                setOtpModalOpen(true);
                toast.error(error.response.data.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 8000, //6 seconds
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  transition: Slide
                });
            })
         }
        
          }

     
      function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
            backdrop='static' 
            keyboard="False"
            dialogClassName='my-modal'
          >
            <Modal.Header className='modal-header'>
              <Modal.Title id="contained-modal-title-vcenter">
              Confirmation Of Account Creation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
              <p>
              Confirmation Of Changes Password Request</p>
            </Modal.Body>
            <Modal.Footer>
            <button className="ui button comm-button-style primary" >LOGOUT</button>
            </Modal.Footer>
          </Modal>
        );
      }
      async function handleSubmit() {
        console.log("HI FROM CHange phone number")
        setIsUpdateButtonActive(true);
        console.log("userDetailsObject.phoneNumber"+userDetailsObject.phoneNumber)
        otpInputModelOpenendForAction = "changePhonenumber";
        
        //first call new mobile service
        try {
          const newMobileServiceResponse=await UserService.updateMobileNumber(userDetailsObject);
          if(newMobileServiceResponse){
            try {
              const response = await ProjectsService.getStrongConfirmationCode("POST",null,"changePhoneNumber",null,null,null)
          if (response && response.data.channel === "SMS") {
              otpInputModelOpenendForAction = "changePhoneNumber";
              
              setOtpModalOpen(true);
             
            }
         
              
          } catch (error) {
              console.log(error);
          }
          }
        } catch (error) {
          console.log(error)
        }

       
      }
 
    return(
    <>
     <div className="ui bottom attached tab segment active change-phone-number-mpc" data-tab="changePhoneNumber">
        <ul className="pass-elements">
          <li>
            <h3>Change Phone Number</h3>
          </li>
          <li>
                    <div className="contact-num-wrap">
                    <div class="country-code-w">
                    {!countryCode?  <span className="countryCode-label">Select Country Code</span>:<></>}
                    <PhoneInput
        country={""}
        value={phoneNumber}
        enableSearch={true}
        //onChange={code => setCountryCode(code)}
        onChange={(phone,country) => {setPhoneNumber(phone);
          setCountryCode(country.countryCode);
}}
        placeholder="Enter phone number"
        inputProps={{
            required: true,
          }}
        
      />
       <span className="required-field-op">(Required)</span>
                    </div>
                    </div>
                    <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Mobile number is invalid</span>
                  </li>
                
     
              <li>
                <div className="apim-pop-button-w comm-button-wrap">
                  <button className="ui button comm-button-style primary apim-reset-pass-btn btn-disable" id="updateBtn"  onClick={handleSubmit}>Update</button>
                  <button className="ui button comm-button-style secondary custom-loader" id="loadingBtn" style={{display: 'none'}}>
                    <i className="icon-plus iconLoader" />
                    Changing phone Number..
                  </button>
                  <button className="ui button comm-button-style secondary apim-pop-button-cancel" id="cancelBtn">Cancel</button>
                </div>
              </li>

        </ul>
        
      </div>
      <div className="toast-container"><ToastContainer limit={4}/></div>
      {OtpModalOpen &&<OtpInputModal ModalHeading={""} setOpenOtpModal={setOtpModalOpen} handleOtp={handleOtp} value={OtpDataFromModal}  verifyOtp={verifyOtp} credentialRecord={""} otpInputModelOpenendForAction={otpInputModelOpenendForAction} newMobile={userDetailsObject.mobile}/>}
    
    </>
    )
}
export default ChangePhoneNumber;