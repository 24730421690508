import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./MembersModal.css";
import { useParams } from "react-router-dom";
import ProjectsService from "../../Services/ProjectsService";
function AddAPIDialog({
  ModalHeading,
  setAPIDilogOpen,
  addNewAPIAndProperties,
  env,
}) {
  const [optionValue, setOptionValue] = useState(0);
  const [showModal, setShow] = useState({ setAPIDilogOpen });
  const handleClose = () => {
    setAPIDilogOpen(false);
    setOptionValue(0);
  };
  const handleShow = () => setShow(true);
  const { companyId, projectId } = useParams();
  const [availableApis, setAvailableApis] = useState([]);

  useEffect(() => {
    ProjectsService.getProjectsAvailableApisByProjectId(projectId)
      .then((response) => {
        setAvailableApis(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = async () => {
    alert("inside handle submit");
  };

  //const [selectedItem, setSelectedItem] = useState("Select from the list of available APIs")
  const handleChange = (e) => {
    setOptionValue(e.target.value);
    console.log("-------------------" + e.target.value);
  };
  return (
    <div
      className="modalBackground"
      style={{ opacity: "1", width: "100vw", height: "100vh" }}
    >
      <Modal
        animation={false}
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard="False"
        dialogClassName="my-modal"
      >
        <Modal.Header className="apim-pop-header" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {ModalHeading}
          </Modal.Title>
          <div class="rightSec-head">
            <a href="javascript:;" class="pop-close-wrap mpa-button-cancel">
              <span class="icon-remove"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-input-wrapp has-validation">
            <select
              className="form-select"
              name="item-selected"
              value={optionValue}
              onChange={handleChange}
            >
              <option value={0} selected="">
                Select from the list of available APIs
              </option>
              {availableApis &&
                availableApis.map((api) => {
                  return <option value={api.id}>{api.name}</option>;
                })}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              addNewAPIAndProperties(optionValue, env, "");
            }}
            id="addAPIDialogButton"
            disabled={optionValue == 0}
          >
            Add
          </Button>
          <Button
            onClick={() => {
              setAPIDilogOpen(false);
            }}
            id="closeButton"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default AddAPIDialog;
