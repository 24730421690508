import React, { useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import PasswordChecklist from "react-password-checklist";
import OtpInputModal from '../Company/OtpInputModal';
import ProjectsService from '../../Services/ProjectsService';
import { Outlet} from "react-router-dom";
import { ToastContainer, toast ,Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserService from '../../Services/UserService';
import Logout from '../_Authentication/Logout';

const ChangePassword = () => {

  const [repeatPassword, setRepeatPassword] = useState('');
  const [password, setPassword] = useState('');
  const [modalShow, setModalShow] = React.useState(false);
  const [OtpModalOpen, setOtpModalOpen] = useState(false);
  const[OtpDataFromModal,setOtpDataFromModal] = useState([]);
  const [isUpdateButtonActive, setIsUpdateButtonActive] = useState(false);
  const [changePassLogout, setChangePassLogout] = useState(false);
 
  const handleOtp = (OtpDataToProject) => {
    setOtpDataFromModal(OtpDataToProject);
  }
    let userDetailsObject = {
        password: ""
      };
      userDetailsObject.password=password;
    // Style for the strikethrough effect 
    const strikethroughStyle = { 
        textDecoration: 'line-through', 
    };
    var otpInputModelOpenendForAction = '';
      
      async function verifyOtp(OtpDataFromModal){
  
        if(OtpDataFromModal.length!==0){
        
          console.log(OtpDataFromModal)
          OtpDataFromModal  = Number(OtpDataFromModal.join(''));
          console.log("otp:"+OtpDataFromModal)
            await UserService.changeUserPasswordFromProfilePage(userDetailsObject, OtpDataFromModal).then((response) => {
              setIsUpdateButtonActive(false); 
              setOtpModalOpen(false);
              toast.success(response.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 8000, //6 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                transition: Slide
              });
              setModalShow(true);
             
          }).catch(error =>{
              console.log(error);
              setOtpModalOpen(true);
              toast.error(error.response.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 8000, //6 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                transition: Slide
              });
          })

         }
        
          }

      function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
            backdrop='static' 
            keyboard="False"
            dialogClassName='my-modal'
          >
            <Modal.Header className='modal-header'>
              <Modal.Title id="contained-modal-title-vcenter">
              Confirmation Of Account Creation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
              <p>
              Confirmation Of Changes Password Request</p>
            </Modal.Body>
            <Modal.Footer>
            <a className="ui button comm-button-style primary"  onClick={() => setChangePassLogout(true)}>LOGOUT</a>
            </Modal.Footer>
          </Modal>
        );
      }
      async function handleSubmit() {
       // alert("HI")
        console.log("HI FROM CHange password")
        //e.preventDefault();
        setIsUpdateButtonActive(true);
        //userDetailsObject.password=password;
        console.log("userDetailsObject.password"+userDetailsObject.password)
        otpInputModelOpenendForAction = "changePassword";
        
        // 
        try {
            const response = await ProjectsService.getStrongConfirmationCode("PUT",null,"changePassword",null,null,null);
            //const optionsResponse=await  axios.options("https://api-gateway-pp.nets.eu/self-service/v3.4.0/getStrongConfirmationCode",{},{ headers: {'Content-Type': 'application/json; charset=utf-8'} })
           // await ProjectsService.getStrongConfirmationCode("OPTIONS",null,"changePasswordMyProfile",null,null,null)
        if (response && response.data.channel == "SMS") {
            otpInputModelOpenendForAction = "changePasswordMyProfile";
            
            setOtpModalOpen(true);
           // alert("otp entered from otp modal for deleting credentials is ---->" + OtpDataFromModal);
          }
       
            
        } catch (error) {
            console.log(error);
        }
       // setModalShow(true);  //enable here for modal testing
       
      }
 
        return (
            

            <div id="joinUsContainer" className="container-fluid" style={{height:'100%'}}>
                

 
<div className="">
<section className="change-pass-wrap ms-4">
<ul className="">
  <li className="ms-2">
  <h3>
CHANGE PASSWORD
</h3>
  </li>
            
            <li>
                    <div className="custom-input-wrapp mt-5">
                    <FloatingLabel
        controlId="passwordInput"
        label="Enter new password"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setPassword(event.target.value)}
      >
        <Form.Control type="password" placeholder=" " />
        <span className="required-field-op">(Required)</span>
        <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Password is required.</span>
      </FloatingLabel>
                    </div>
                  </li>
                  <li>
                    <div className="custom-input-wrapp">
                    {password.length!==0 ?<FloatingLabel
        controlId="repeatPasswordInput"
        label="Repeat password"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setRepeatPassword(event.target.value)}
      >
        <Form.Control type="password" placeholder=" " />
        <span className="required-field-op">(Required)</span>
        <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Repeat password is required.</span>
      </FloatingLabel> : <></>}
                    </div>
                  </li>
     
              <li>
                <div className="apim-pop-button-w comm-button-wrap">
                  <button className="ui button comm-button-style primary apim-reset-pass-btn btn-disable" id="updateBtn"  onClick={handleSubmit}>Update</button>
                  <button className="ui button comm-button-style secondary custom-loader" id="loadingBtn" style={{display: 'none'}}>
                    <i className="icon-plus iconLoader" />
                    Changing Password..
                  </button>
                  <button className="ui button comm-button-style secondary apim-pop-button-cancel" id="cancelBtn">Cancel</button>
                </div>
              </li>
            </ul>
            <div className="cp-myp-left-sec">
            {
 password.length===0 ?
 <></>
 :
 
              
              <div className="pass-check">
                {/* <h3>Passwords must contain</h3> */}
                <ul>
                
         <PasswordChecklist
            rules = {[
               "capital",
               "match",
               "specialChar",
               "minLength",
               "lowercase",
               "number",
            ]}
            iconSize={15}
            minLength = {8}
            value = {password}
            valueAgain = {repeatPassword}
            messages = {{
               minLength: "The minimum length of the password should be 8.",
               specialChar:
               "The password should contain at least one special character.",
               number: "The password should contain at least one numeric letter.",
               capital: "The password should contain at least one uppercase letter.",
               match: "Password and repeat password should match.",
               lowercase: "The password should contain at least one lowercase letter.",
            }}
         />
                </ul>
              </div>
}
            </div>
           
          </section>
          <MyVerticallyCenteredModal
        show={modalShow}
      />
      <div className="toast-container"><ToastContainer limit={4}/></div>
     
      {OtpModalOpen &&<OtpInputModal ModalHeading={""} setOpenOtpModal={setOtpModalOpen} handleOtp={handleOtp} value={OtpDataFromModal}  verifyOtp={verifyOtp} credentialRecord={""} otpInputModelOpenendForAction={otpInputModelOpenendForAction}/>}
              </div>
              <Outlet />
              <Logout logout={changePassLogout}/>
            </div>
          );
    

}
export default ChangePassword;