import Metadata from '../../ServiceReqMetadata';
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import FormSelect from 'react-bootstrap/FormSelect';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { Button, Spinner,Popover} from 'react-bootstrap'
import AddNewAccountableRepresentative from './AddNewAccountableRepresentative';
import ReplaceAnAccountableRepresentative from './ReplaceAnAccountableRepresentative';
import ModiFyRegistrationDetails from './ModiFyRegistrationDetails';
import DeleteCompanyRecords from './DeleteCompanyRecords';

const ManageCompany = ({rqValue,company, requestSubType, confirmedRepId}) => {
    const [requestSubTypeDisplayName, setRequestSubTypeDisplayName] = useState([]);
   const [selectedRequestTypeValue, setSelectedRequestTypeValue] = useState(requestSubType==="ReplaceCRep"?
   "Replace an accountable representative":'');
   const [popoverMsg, setPopoverMsg] = useState('');
   const handleSelectChange = (event) => {
    let selectedValue=event.target.value;
    var msg = Metadata.filter((record) => record.requestType === rqValue).map(
      (e) => {
        return e.requestSubTypes
          .filter((record) => record.displayName === selectedValue)
          .map((n) => {
            return n.whenToUse;
          });
      }
    );
    setPopoverMsg(msg);
    setSelectedRequestTypeValue(selectedValue);
  };
   const customstyles = {
    dropdownindicator: base => ({
      ...base,
      fill: "red" // custom colour
    })
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" style={"white-space: pre-line;"} {...props}>
    {selectedRequestTypeValue}
    
    </Tooltip>
  );
  const popover = (data) =>(  
  
    <Popover id="popover-basic" style={{maxWidth: '700px',height:'90px',borderColor:'black',borderStyle:'solid'}}>    
      <Popover.Body className='row'>  
        <p  className='row'> {data}</p>
      </Popover.Body>  
    </Popover>  
  ); 
 
    useEffect(() => {
        var requestSubTypes = Metadata.filter(record => record.requestType===rqValue) 
        .map((e) => {
           return e.requestSubTypes.map((n)=>{
            
            return n.displayName})
        } );
        setRequestSubTypeDisplayName(requestSubTypes);
        //console.log("ServiceReqMetadata::------->>"+Metadata.map((e) =>  e.requestType ));
        console.log("displayName....::------->>"+JSON.stringify(requestSubTypes));
        
          
        },[])
    return(
        <section className="serv-req-cre-main mt-3 mb-3">
  <ul className="serv-req" id="serviceRequestDD">
    <li>
      <div className="drop-d-w">
        <div
          id="requestTypeDD"
          className="ui selection dropdown"
          tabIndex={1}
          style={{}}
        >
          <input type="hidden" name="selectSerReq" defaultValue />
          <i className="dropdown icon" />

          <div>
          <Form.Select   defaultValue="Select Sub Type"value={selectedRequestTypeValue} styles={customstyles} style={{width:'100%'}} onChange={handleSelectChange}>
      <option className="textdefault" selected value={"Select Sub Type"}>{"Select Sub Type"}</option>
        {requestSubTypeDisplayName[0] && requestSubTypeDisplayName[0].map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </Form.Select>
            {/* <select size={'lg'} defaultValue="Select Service Request"value={selectedRequestTypeValue} onChange={handleSelectChange}styles={customstyles} style={{width:'100%'}}>
<option className="textdefault" selected value={"Select Service Request"}>{"Select Service Request"}</option>
{requestTypeDisplayName.map((option, index) => (
<option key={index} value={option}>{option}</option>
))}
</select> */}
          </div>
        </div>
        <div className="col-sm-1">
        {popoverMsg!=''&&
        <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={popover(popoverMsg)}
      >
        <a
          className="info-icon-w ms-2"
          target="_blank"
        >
          <span className="bi bi-info-circle mt-2" />
        </a>
      </OverlayTrigger>
        }
        </div>
        
      </div>
    </li>
  </ul>
  <ul className="after-sel-sec serviceRequestFormBuilder">
    {/* to render different components on selection of select options */}
    <li>
                                  {selectedRequestTypeValue === "Modify Registration Details" && <ModiFyRegistrationDetails />}
                                  {selectedRequestTypeValue === "Add new accountable representative." && <AddNewAccountableRepresentative rqValue={selectedRequestTypeValue} />}
                                  {selectedRequestTypeValue === "Replace an accountable representative" && <ReplaceAnAccountableRepresentative company={company} confirmedRepId={confirmedRepId}/>}  
                                  {selectedRequestTypeValue === "Delete Company Records" && <DeleteCompanyRecords />} 
                              </li>
  </ul>
</section>

            );
    }
    export default ManageCompany;