import { Outlet} from "react-router-dom";
import logo from '../../Assets/Images/nets-logo-white.png';
import lock from '../../Assets/Images/lock_closed.svg';
import Cookies from 'universal-cookie';
import login from '../../Components/_Authentication/Login';
import { NETS_MAIN_WEBSITE_URL } from '../../Config/AppConfiguration';

const HeaderLogin = () =>  {
const cookieNameToken = "accessToken";
const callLogin =  ()  =>{
  const cookies = new Cookies();
  if(cookies.get(cookieNameToken) == null){
    console.log('------------no cookie------------');
    window.location.href = login.getLoginUrl(); 
  };
};
  return (
    <div className="container-fluid">
      <nav className="navbar navbar-expand-lg  navbar-dark bgblue" width="100%">
        {/* Container wrapper */}
        <div className="container-fluid">
          {/* Navbar brand */}
          <a className="navbar-brand ms-3" href="/">
            <img width={169} src={logo} alt="Nets Logotype" />
          </a>
          {/* Toggle button */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i className="navbar-toggler-icon" />
          </button>
          {/* Collapsible wrapper */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Left links */}
            <ul className="navbar-nav navbar-dark me-auto mb-2 mb-lg-0">
            </ul>
            {/* Left links */}
            <ul className="navbar-nav ms-3 ">
              <li className="nav-item item-center">
                <a className="nav-link" href={NETS_MAIN_WEBSITE_URL} target="_blank"><h6>GO TO NETS MAIN WESITE</h6></a>
              </li>
              <li className="nav-item item-center">
                <a className="nav-link" href="/joinUs"><h6>JOIN</h6></a><h6><a className="nav-link" href="#" />
                </h6></li>
                <li class="nav-item dropdown">
               <a className="nav-link me-lg-3" variant="success" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
               <img width={42} height={42} src={lock} alt="lock" />
               
                
              </a>               
  
<ul className="dropdown-menu dropdown-menu-end p-0 " aria-labelledby="navbarDropdown" style={{backgroundColor: '#fff'}}>  
                  <li><a className="dropdown-item" href="#" onClick={() => callLogin()} >Login</a></li>
                  <li><a className="dropdown-item" href="/resetPassword">Reset Password</a></li>
                </ul>
        </li>
             
            </ul>
          </div>
          {/* Collapsible wrapper */}
        </div>
        {/* Container wrapper */}
      </nav>
      {/* Navbar */}

    <Outlet />
    </div>
       );
      }

export default HeaderLogin;
